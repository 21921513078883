import React, { Component } from 'react';
import Lottie from 'react-lottie';

import getAvatarById from "constants/avatars";

import styles from "components/PlayerStyles.module.scss";

import mooLeftAnim from "animations/mooLeft.js";
import mooRightAnim from "animations/mooRight.js";

import cowToken from "images/cow-token.png";
import pinkCow from "images/pink-cow-icon.png";
import readyTag from "images/ready-tag.png";


export default class Player extends Component {

    constructor(props) {
        super(props);
        let fontSize = this.getFontSize(props.player);
        let answerFontSize = this.getAnswerFontSize(props.player);
        this.state = {
            fontSize: fontSize,
            answerFontSize: answerFontSize,
            mooIsPaused: true,
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.player) {
            if (nextProps.player.mooIsPaused != null) {
                this.setState({ mooIsPaused: nextProps.player.mooIsPaused, });
            }
        }
        this.setState({ fontSize: this.getFontSize(nextProps.player), answerFontSize: this.getAnswerFontSize(nextProps.player),  });
    }


    getFontSize(player) {
        if (player) {
            let name = player.name;
            let fontSize = "";
            if (name.length <= 3) {
                fontSize = "large";
            } else if (name.length <= 6) {
                fontSize = "medium";
            } else {
                fontSize = "small";
            }
            return fontSize;
        } else {
            return "medium";
        }
    }

    getAnswerFontSize(player) {
        if (player) {
            let answer = player.herdData.answer;
            let fontSize = "";
            if (answer.length <= 20) {
                fontSize = "large";
            } else if (answer.length <= 30) {
                fontSize = "medium";
            } else {
                fontSize = "small";
            }
            return fontSize;
        } else {
            return "medium";
        }
    }

    getCowTokens(number) {
        let cowTokens = [];
        for (let i = 0; i < number; i++) {
            cowTokens.push(<img src={cowToken} className={`${styles.cowToken} ${this.props.player.herdData.hasPinkCow ? styles.fade : ""}`} />)
        }
        return cowTokens;
    }

    render() {
        return (
            this.props.isEmpty ?
                <div className={`${styles.player} ${this.props.left ? "" : styles.reverse}`} />
                :
                <div id={`player-${this.props.player.id}`} className={`${styles.player} ${this.props.left ? "" : styles.reverse}`}>
                    <div className={styles.playerSection}>
                        <div
                            id={`potato-${this.props.player.id}`}
                            className={styles.potato}
                        >
                            <Lottie
                                options={getAvatarById(this.props.player.avatar).idleAnim}
                                width="100%"
                                height="100%"
                                isClickToPauseDisabled={true}
                            />
                        </div>
                        
                        <div className={styles.textSection}>
                            <div id="nameText" className={`${styles.nameText} ${this.props.wrangler.id == this.props.player.id ? styles.wrangler : ""} ${this.state.fontSize == "large" ? styles.large : this.state.fontSize == "medium" ? styles.medium : styles.small}`}>{this.props.player.name}</div>
                        </div>
                    </div>
                    <div className={`${styles.boxSection} ${this.props.player.herdData.confirmed ? styles.confirmed : ""}`}>
                        <div id={`anchor-${this.props.player.id}`} className={styles.anchor} />
                        <img className={`${styles.bg} ${this.props.player.herdData.hasPinkCow ? styles.pink : ""}`} />
                        <img src={pinkCow} className={`${styles.pinkCow} ${this.props.player.herdData.hasPinkCow ? styles.show : ""}`} />
                        <img src={readyTag} className={`${styles.answeredText} ${this.props.left ? styles.left : styles.right} ${this.props.player.hasAnswered ? styles.show : ""}`} />
                        <div className={`${styles.mooBubble} ${!this.props.left ? styles.right : styles.left}`}>
                            {
                                this.state.mooIsPaused ?
                                    null
                                    :
                                    <Lottie
                                        options={this.props.left ? mooLeftAnim : mooRightAnim}
                                        width="100%"
                                        height="100%"
                                        isClickToPauseDisabled={true}
                                        eventListeners={[
                                            {
                                                eventName: 'DOMLoaded',
                                                callback: () => {
                                                    console.log("DOMLoaded")
                                                }
                                            },
                                            {
                                                eventName: 'complete',
                                                callback: () => {
                                                    this.props.doMoo(this.props.player.id, true)
                                                }
                                            },
                                        ]}
                                    />
                            }
                        </div>
                        {/*<img src={this.props.left ? mooLeft : mooRight} className={`${styles.mooBubble} ${!this.props.left ? styles.right : ""} ${this.props.player.showMoo ? styles.show : ""}`} />*/}
                        <div className={`${styles.scoreSection} ${this.props.showAnswers ? styles.hidden : ""} ${this.props.left ? styles.left : styles.right}`}>
                            {
                                this.getCowTokens(this.props.player.herdData.score)
                            }
                            <div className={`${styles.scoreContainer} ${this.props.left ? styles.left : styles.right}`}>
                                <div className={styles.scoreText}>{this.props.player.herdData.score}</div>
                            </div>
                        </div>
                        <div className={`${styles.answerText}
                                        ${this.props.player.herdData.selected ? this.props.assigningPink ? styles.selectedPink : styles.selected : ""}
                                        ${this.state.answerFontSize == "large" ? styles.large : this.state.answerFontSize == "medium" ? styles.medium : styles.small}
                                        ${this.props.player.showAnswer ? styles.show : ""}
                        `}>
                            {this.props.player.herdData.answer}
                        </div>
                    </div>
                </div>
        )
    }
}