import React, { Component } from 'react';
import ReactPlayer from 'react-player'

import styles from 'components/TutorialStyles.module.scss';

export default class Tutorial extends Component {

    constructor(props) {
        super(props);
        this.state = {
            source: "https://youtu.be/wxoNQkxulL4"
        }

        this.tutorialInterval = null;
    }

    componentWillReceiveProps(nextProps) {
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        if (this.tutorialInterval) clearInterval(this.tutorialInterval);
    }

    endTutorial() {
        this.props.room.send("end_tutorial");
    }

    render() {
        return (
            <div className={`${styles.tutorialBG} ${this.props.show ? styles.show : ""}`}>
                <div className={`${styles.tutorial} ${this.props.show ? styles.show : ""}`}>
                    <ReactPlayer
                        className='react-player'
                        url={`${this.state.source}`}
                        width='100%'
                        height='100%'
                        playing={true}
                        volume={0.5}
                        stopOnUnmount={true}
                        onEnded={() => { this.endTutorial() }}
                    />
                </div>
                <div className={`${styles.skipCount} ${this.props.show ? styles.show : ""}`}>Skip vote: {this.props.skipCount}/{this.props.playerCount}</div>
            </div>
        )
    }

}