import cowLeft from "../images/cow-left.png";
import cowRight from "../images/cow-right.png";
import cowToken from "../images/cow-token.png";
import grassBg from "../images/grass-bg.png";
import herdFullscreen from "../images/herd_fullscreen.png";
import herdHelp from "../images/herd_help.png";
import herdMuted from "../images/herd_muted.png";
import herdUnmuted from "../images/herd_unmuted.png";
import logo from "../images/logo.png";
import mooButton from "../images/moo-button.png";
import mooLeft from "../images/moo-left.png";
import mooRight from "../images/moo-right.png";
import muted from "../images/muted.png";
import notificationBg from "../images/notification-bg.png";
import notificationBg2 from "../images/notification-bg-2.png";
import patternBg from "../images/pattern-bg.png";
import penBack from "../images/pen-back.png";
import penFront from "../images/pen-front.png";
import pinkCowIcon from "../images/pink-cow-icon.png";
import pinkCowLeft from "../images/pink-cow-left.png";
import pickCowRight from "../images/pink-cow-right.png";
import potato from "../images/potato.png";
import questionFooter from "../images/question-footer.png";
import questionFooter2 from "../images/question-footer-2.png";
import readyTag from "../images/ready-tag.png";
import unmuted from "../images/unmuted.png";
import winnerBg from "../images/winner-bg.png";

import playerBox1 from "../images/PlayerBoxes/player-box-1.png";
import playerBox1Pink from "../images/PlayerBoxes/player-box-1-pink.png";
import playerBox2 from "../images/PlayerBoxes/player-box-2.png";
import playerBox2Pink from "../images/PlayerBoxes/player-box-2-pink.png";
import playerBox3 from "../images/PlayerBoxes/player-box-3.png";
import playerBox3Pink from "../images/PlayerBoxes/player-box-3-pink.png";
import playerBox4 from "../images/PlayerBoxes/player-box-4.png";
import playerBox4Pink from "../images/PlayerBoxes/player-box-4-pink.png";
import playerBox5 from "../images/PlayerBoxes/player-box-5.png";
import playerBox5Pink from "../images/PlayerBoxes/player-box-5-pink.png";
import playerBox6 from "../images/PlayerBoxes/player-box-6.png";
import playerBox6Pink from "../images/PlayerBoxes/player-box-6-pink.png";
import playerBox7 from "../images/PlayerBoxes/player-box-7.png";
import playerBox7Pink from "../images/PlayerBoxes/player-box-7-pink.png";
import playerBox8 from "../images/PlayerBoxes/player-box-8.png";
import playerBox8Pink from "../images/PlayerBoxes/player-box-8-pink.png";

const images = [
    cowLeft,
    cowRight,
    cowToken,
    grassBg,
    herdFullscreen,
    herdHelp,
    herdMuted,
    herdUnmuted,
    logo,
    mooButton,
    mooLeft,
    mooRight,
    muted,
    notificationBg,
    notificationBg2,
    patternBg,
    penBack,
    penFront,
    pinkCowIcon,
    pinkCowLeft,
    pickCowRight,
    potato,
    questionFooter,
    questionFooter2,
    readyTag,
    unmuted,
    winnerBg,
    playerBox1,
    playerBox1Pink,
    playerBox2,
    playerBox2Pink,
    playerBox3,
    playerBox3Pink,
    playerBox4,
    playerBox4Pink,
    playerBox5,
    playerBox5Pink,
    playerBox6,
    playerBox6Pink,
    playerBox7,
    playerBox7Pink,
    playerBox8,
    playerBox8Pink,
];

const fonts = [
    { font: "DearType", path: `https://${window.location.host}/fonts/DearType-HelloHeadline.otf` },
    { font: "KCHand", path: `https://${window.location.host}/fonts/KCIllHand.otf` },
    { font: "Metallophile", path: `https://${window.location.host}/fonts/MarkSimonson-MetallophileSp8Medium.otf` },
];

export default {
    images,
    fonts,
};