import React, { Component } from 'react';
import * as Colyseus from "colyseus.js";
import { Route } from 'react-router';
import Lottie from 'react-lottie';
import QRCode from 'qrcode.react';
import { Howl, Howler } from "howler";
import Confetti from 'react-confetti';
import LoggingService from "services/logging";
import * as Sentry from "@sentry/react";

import Loading from "components/Loading";
import Player from "components/Player";
import Menu from "components/Menu";

import timerTurning from "animations/timerTurning.js";
import timerEnd from "animations/timerEnd.js";
import tickCard from 'animations/tickCard.js';
import winnerIsAnim from 'animations/titles/winnerIs.js';
import howToPlayAnim from 'animations/titles/howToPlay.js';
import answersTitleAnim from 'animations/titles/answersTitle.js';
import round1Anim from 'animations/titles/round1.js';
import round2Anim from 'animations/titles/round2.js';
import round3Anim from 'animations/titles/round3.js';
import round4Anim from 'animations/titles/round4.js';
import round5Anim from 'animations/titles/round5.js';
import round6Anim from 'animations/titles/round6.js';
import round7Anim from 'animations/titles/round7.js';
import round8Anim from 'animations/titles/round8.js';

import getAvatarById from "constants/avatars";
import penBack from "images/pen-back.png";
import penFront from "images/pen-front.png";
import notificationBg from "images/notification-bg-2.png";
import questionFooter from "images/question-footer-2.png";
import logoImg from "images/logo.png";
import winnerBg from "images/winner-bg.png";
import cowLeftImg from "images/cow-left.png";
import cowRightImg from "images/cow-right.png";
import pinkCowLeftImg from "images/pink-cow-left.png";
import pinkCowRightImg from "images/pink-cow-right.png";
import mutedIcon from "images/herd_muted.png";
import unmutedIcon from "images/herd_unmuted.png";
import fullscreenIcon from "images/herd_fullscreen.png";
import helpIcon from "images/herd_help.png";
import pinkCowHead from "images/pink-cow-icon.png";
import Tutorial from "components/Tutorial";

import playingBgMusic from "audio/playing-bg-music.mp3";
import awardCowSFX from "audio/award-cow.wav";
import awardPinkCowSFX from "audio/award-pink-cow.wav";
import bannerWooshSFX from "audio/banner-woosh.wav";
import hoverOrClickSFX from "audio/hover-click.mp3";
import joinedSFX from "audio/player-joined.wav";
import pinkCowBackerSFX from "audio/pink-cow-background.wav";
import selectedButtonSFX from "audio/phone-select-or-skip.wav";
import selectingAnswersSFX from "audio/phone-selecting-answers.wav";
import readySFX from "audio/ready.wav";
import winnerSFX from "audio/winner.wav";
import cow1SFX from "audio/CowMoo_01.wav";
import cow2SFX from "audio/CowMoo_02.wav";
import cow3SFX from "audio/CowMoo_03.wav";
import cow4SFX from "audio/CowMoo_04.wav";
import cow5SFX from "audio/CowMoo_05.wav";
import cow6SFX from "audio/CowMoo_06.wav";
import cow7SFX from "audio/CowMoo_07.wav";
import cow8SFX from "audio/CowMoo_08.wav";
import cow9SFX from "audio/CowMoo_09.wav";
import cow10SFX from "audio/CowMoo_10.wav";

import "animate.css";
import styles from 'components/HostStyles.module.scss';
import ErrorModal from './Utility/ErrorModal';

const fullscreenAvailable = document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled ? true : false;

var audio = {
    BgMusic: {
        import: playingBgMusic,
        loaded: null,
        volume: 0.15,
        loop: true,
    },
    AwardCow: {
        import: awardCowSFX,
        loaded: null,
        volume: 0.6,
        loop: false,
    },
    AwardPinkCow: {
        import: awardPinkCowSFX,
        loaded: null,
        volume: 0.6,
        loop: false,
    },
    BannerWoosh: {
        import: bannerWooshSFX,
        loaded: null,
        volume: 0.6,
        loop: false,
    },
    HoverOrClick: {
        import: hoverOrClickSFX,
        loaded: null,
        volume: 0.6,
        loop: false,
    },
    Joined: {
        import: joinedSFX,
        loaded: null,
        volume: 0.6,
        loop: false,
    },
    PinkCowBacker: {
        import: pinkCowBackerSFX,
        loaded: null,
        volume: 0.6,
        loop: false,
    },
    SelectedButton: {
        import: selectedButtonSFX,
        loaded: null,
        volume: 0.6,
        loop: false,
    },
    SelectingAnswers: {
        import: selectingAnswersSFX,
        loaded: null,
        volume: 0.6,
        loop: false,
    },
    Ready: {
        import: readySFX,
        loaded: null,
        volume: 0.6,
        loop: false,
    },
    Winner: {
        import: winnerSFX,
        loaded: null,
        volume: 0.6,
        loop: false,
    },
    Cow1: {
        import: cow1SFX,
        loaded: null,
        volume: 0.6,
        loop: false,
    },
    Cow2: {
        import: cow2SFX,
        loaded: null,
        volume: 0.6,
        loop: false,
    },
    Cow3: {
        import: cow3SFX,
        loaded: null,
        volume: 0.6,
        loop: false,
    },
    Cow4: {
        import: cow4SFX,
        loaded: null,
        volume: 0.6,
        loop: false,
    },
    Cow5: {
        import: cow5SFX,
        loaded: null,
        volume: 0.6,
        loop: false,
    },
    Cow6: {
        import: cow6SFX,
        loaded: null,
        volume: 0.6,
        loop: false,
    },
    Cow7: {
        import: cow7SFX,
        loaded: null,
        volume: 0.6,
        loop: false,
    },
    Cow8: {
        import: cow8SFX,
        loaded: false,
        volume: 0.6,
        loop: false,
    },
    Cow9: {
        import: cow9SFX,
        loaded: null,
        volume: 0.6,
        loop: false,
    },
    Cow10: {
        import: cow10SFX,
        loaded: null,
        volume: 0.6,
        loop: false,
    },
};


const GameStates = {
    Loading: "loading",
    Tutorial: "tutorial",
    Idle: "idle",
    Question: "question",
    Answers: "answers",
    PinkCow: "pink_cow",
    Results: "results",
    EndGame: "end_game",
};

const gameId = "herd_mentality";


export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            roomId: 0,
            room: null,
            myId: null,
            roomState: null,
            redirect: null,
            reconnectTries: 0,
            connected: false,
            //connected: true,
            muted: false,
            menuOpen: false,
            gameBegun: false,
            reconnectionToken: "",

            roundNumber: 0,
            totalRounds: 8,
            isGame: false,
            //isGame: true,
            players: [],
            playersRequired: 3,
            gameState: GameStates.Loading,
            question: {},
            herdWrangler: {},
            pinkCowHolder: "",
            showNotification: false,
            showWranglerNotification: false,
            showPickNotification: false,
            notification: "",
            showAnswers: false,
            assigningPink: false,
            showRoundResults: false,
            gameOver: false,
            winners: [],
            winnerText: "",
            winnerTitle: "",
            showPlayAgainSection: false,
            shrinkLogo: false,
            showQuestion: false,
            showPen: false,
            timer: "",
            cows: [],
            pinkCow: null,
            timerOptions: timerTurning,
            showHeaderFooter: false,
            doingTutorial: false,
            showTutorial: false,
            maxVoteCount: 0,
            skipTutorialCount: 0,
            showPlayers: false,
            showStartGame: false,

            tickedSkipTutorial: false,
            leaderboardPlayers: [],
            doConfetti: false,
            titles: [
                {
                    options: round1Anim,
                    context: 1,
                    isPaused: true,
                },
                {
                    options: round2Anim,
                    context: 2,
                    isPaused: true,
                },
                {
                    options: round3Anim,
                    context: 3,
                    isPaused: true,
                },
                {
                    options: round4Anim,
                    context: 4,
                    isPaused: true,
                },
                {
                    options: round5Anim,
                    context: 5,
                    isPaused: true,
                },
                {
                    options: round6Anim,
                    context: 6,
                    isPaused: true,
                },
                {
                    options: round7Anim,
                    context: 7,
                    isPaused: true,
                },
                {
                    options: round8Anim,
                    context: 8,
                    isPaused: true,
                },
                {
                    options: answersTitleAnim,
                    context: "Answers",
                    isPaused: true,
                },
                {
                    options: howToPlayAnim,
                    context: "Tutorial",
                    isPaused: true,
                },
                {
                    options: winnerIsAnim,
                    context: "WinnersAre",
                    isPaused: true,
                }
            ],

            gotLocationPing: true,
            connectionIssue: false,

            showStartWarning: false,
            playerConnections: {},
        };
        this.titleRefs = {
            1: React.createRef(),
            2: React.createRef(),
            3: React.createRef(),
            4: React.createRef(),
            5: React.createRef(),
            6: React.createRef(),
            7: React.createRef(),
            8: React.createRef(),
            "Answers": React.createRef(),
            "Tutorial": React.createRef(),
            "WinnersAre": React.createRef(),
        }
        this.toggleMute = this.toggleMute.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.doMoo = this.doMoo.bind(this);

        this.gameBgAudio = new Howl({
            src: playingBgMusic,
            loop: true,
            volume:0.15,
        });

    }

    componentDidMount() {
        this.setTags();
        this.doReconnect();

        this.toggleMute(true, false);
        //this.preloadAudio([
        //    awardCowSFX,
        //    awardPinkCowSFX,
        //    bannerWooshSFX,
        //    hoverOrClickSFX,
        //    joinedSFX,
        //    pinkCowBackerSFX,
        //    readySFX,
        //    winnerSFX,
        //    selectedButtonSFX,
        //    selectingAnswersSFX,
        //    ...MooArray
        //]);

        //this.playAudio(playingBgMusic, true, 0.2);
        Howler.volume(0.6);
        this.gameBgAudio.play();
        this.initAudio();
    }

    setTags() {
        const token = this.getQueryStringValue('token');
        Sentry.setTag('isHost', true);

        if (token) {
            const [roomId, reconnectToken] = token.split(':');
            Sentry.setTag('roomId', roomId);
            Sentry.setTag('reconnectToken', reconnectToken);
        }
    }


    initAudio() {
        console.log("Init audio");
        this.preloadAudio();
        Howler.volume(0.5);
        //this.playAudio(audio.BgMusic, true, 0.15);
    }

    toggleFullScreen() {
        if (fullscreenAvailable) {
            if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                let elem = document.documentElement
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) {
                    elem.webkitRequestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.mozExitFullscreen) {
                    document.mozExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    animateCSS = (element, animation, prefix = 'animate__') =>
        // We create a Promise and return it
        new Promise((resolve, reject) => {
            const animationName = `${prefix}${animation}`;
            let node;
            if (typeof element === `string`) {
                node = document.querySelector(element);
            } else {
                node = element;
            }
            node.classList.add(`${prefix}animated`, animationName);

            // When the animation ends, we clean the classes and resolve the Promise
            function handleAnimationEnd(event) {
                event.stopPropagation();
                node.classList.remove(`${prefix}animated`, animationName);
                resolve('Animation ended');
            }

            node.addEventListener('animationend', handleAnimationEnd, { once: true });
        });


    preloadAudio() {
        for (let key in audio) {
            audio[key].loaded = new Howl({
                src: [audio[key].import],
                preload: true,
                loop: audio[key].loop,
                volume: audio[key].volume
            });
        }
    }

    playAudio(audio) {
        if (audio.loaded) audio.loaded.play();
    }

    toggleMenu() {
        console.log("toggle menu");
        let newVal = this.state.menuOpen == true ? false : true;
        this.setState({ menuOpen: newVal });
    }

    toggleMute(force = false, value = false) {
        let newVal;
        if (force) {
            newVal = value;
        } else {
            newVal = this.state.muted == true ? false : true;
        }

        console.log("muted new val : " + newVal)

        Howler.mute(newVal);
        this.setState({ muted: newVal, });
    }

    removePlayer(id) {
        this.setState((prevState) => {
            return { players: prevState.players.filter(x => x.id != id), }
        });
    }

    addPlayer(player) {
        if (!this.state.players.find(elem => elem.id == player.id)) {
            player.mooIsPaused = true;
            this.setState((prevState) => {
                return { players: [...prevState.players, player] }
            });
        }
    }

    populateLeftColumn() {
        let players = [];
        let ids = [0, 2, 4, 6];
        for (let i = 0; i < this.state.players.length; i++) {
            if (ids.includes(i)) {
                if (this.state.players[i]) {
                    players.push(<Player doMoo={this.doMoo} assigningPink={this.state.assigningPink} showAnswers={this.state.showAnswers} player={this.state.players[i]} left={true} wrangler={this.state.herdWrangler} />);
                } else {
                    players.push(<Player isEmpty={true} left={true} />);
                }
            }
        }
        return players;
    }

    populateRightColumn() {
        let players = [];
        let ids = [1, 3, 5, 7];
        for (let i = 0; i < this.state.players.length; i++) {
            if (ids.includes(i)) {
                if (this.state.players[i]) {
                    players.push(<Player doMoo={this.doMoo} assigningPink={this.state.assigningPink} showAnswers={this.state.showAnswers} player={this.state.players[i]} left={false} wrangler={this.state.herdWrangler} />);
                } else {
                    players.push(<Player isEmpty={true} left={false} />);
                }
            }
        }
        return players;
    }

    animatePotato(id, animation) {
        if (document.getElementById(`potato-${id}`)) {
            this.animateCSS(`#potato-${id}`, animation);
        }
    }

    doNewQuestion() {
        if (this.notificationTimeout) clearTimeout(this.notificationTimeout);
        this.setState({ shrinkLogo: true, showPen: true, showNotification: true, showWranglerNotification: true, notificationPotato: getAvatarById(this.state.herdWrangler.avatar).idleAnim, notificationName: this.state.herdWrangler.name, notification: `is the Cow Wrangler`, });
        this.notificationTimeout = setTimeout(() => {
            this.setState({ showNotification: false, showQuestion: true, });
            this.state.room.send("show_question", {});
            setTimeout(() => {
                this.setState({ showWranglerNotification: false, notification: "", });
            }, 2000);
        }, 4000);
    }

    showPlayerAnswered(id) {
        let players = [...this.state.players];

        let pos = players.map(function (e) { return e.id; }).indexOf(id);
        let player = { ...players[pos] };

        player.hasAnswered = true;

        players[pos] = player;

        this.setState({ players });
    }

    hideAllPlayerAnswered() {
        let players = [...this.state.players];
        players.forEach((value, key) => {
            value.hasAnswered = false;
        });
        this.setState({ players, });
    }

    doMoo(id, mooIsPaused) {
        let players = [...this.state.players];

        let pos = players.map(function (e) { return e.id; }).indexOf(id);
        let player = { ...players[pos] };

        player.mooIsPaused = mooIsPaused;

        players[pos] = player;

        this.setState({ players });
    }


    stopAllMoos() {
        let players = [...this.state.players]
        for (let i = 0; i < players.length; i++) {
            let player = { ...players[i] };
            player.mooIsPaused = true;
            players[i] = player;
            this.setState({ players });
        }
    }

    showAnswers() {
        if (!this.state.showAnswers) {
            this.setState({ showAnswers: true, });
            this.hideAllPlayerAnswered();
            this.stopAllMoos();
            let statePlayersLength = [...this.state.players].length;
            for (let i = 0; i < statePlayersLength; i++) {
                setTimeout(() => {
                    let statePlayers = [...this.state.players];
                    this.playAudio(audio.Ready);
                    let player = { ...statePlayers[i] };
                    player.showAnswer = true;
                    statePlayers[i] = player;
                    this.setState({ players: statePlayers });

                    if (i == statePlayersLength - 1) {
                        if (this.notificationTimeout) clearTimeout(this.notificationTimeout);
                        this.setState({ showNotification: true, showPickNotification: true, notification: "TIME TO PICK THE WINNERS!" });
                        this.state.room.send("show_answers", {});
                        this.notificationTimeout = setTimeout(() => {
                            this.setState({ showNotification: false, });
                        }, 5000);
                    }
                }, (i * 1500));
            }
        }
    }

    selectAnswer(id, selected) {
        let players = [...this.state.players];
        if (this.state.assigningPink) {
            players.forEach((value) => {
                value.herdData.selected = false;
            });
        }

        let pos = players.map(function (e) { return e.id; }).indexOf(id);
        let player = { ...players[pos] };

        player.herdData.selected = selected;

        players[pos] = player;

        this.setState({ players });
    }

    hideSelected(idList) {
        let players = [...this.state.players];
        players.forEach(x => {
            if (idList.includes(x.id)) {
                x.herdData.confirmed = true;
                x.herdData.selected = false;
            }
        });
        this.setState({ players, });
    }

    unselectAll() {
        let players = [...this.state.players];
        players.forEach((value, key) => {
            value.herdData.selected = false;
        });
        this.setState({ players, });
    }

    startAssignPinkCow() {
        if (this.notificationTimeout) clearTimeout(this.notificationTimeout);
        this.setState({ assigningPink: true, showNotification: true, showPickNotification: true, notification: "WHO SHOULD GET THE PINK COW?" });
        this.notificationTimeout = setTimeout(() => {
            this.setState({ showNotification: false, });
        }, 5000);
    }

    awardPinkCow(id) {
        let deleteCows = [];

        let cows = [...this.state.cows];
        cows.forEach((cow, index) => {
            if (cow.delete) {
                deleteCows.push({
                    index,
                    x: cow.x,
                    y: cow.y,
                });
            }
        });

        if (id && this.state.pinkCowHolder != id) {
            let pinkCow = { ...this.state.pinkCow };
            //pinkCow.shake = true;
            pinkCow.show = true;
            pinkCow.speed = 0.4;

            this.setState({ pinkCow, pinkCowHolder: id, });

            setTimeout(() => {
                let players = [...this.state.players];
                players.forEach((value, key) => {
                    value.herdData.hasPinkCow = false;
                    value.herdData.givenPinkCow = false;
                });
                let pos = players.map(function (e) { return e.id; }).indexOf(id);
                let player = { ...players[pos] };
                let movePos = this.getMovePosition(`pink-cow`, `anchor-${player.id}`);
                pinkCow.x = movePos.x;
                pinkCow.y = movePos.y;
                pinkCow.speed = 1.5;
                pinkCow.delete = true;
                //pinkCow.shake = false;

                this.setState({ pinkCow, });

                setTimeout(() => {
                    players = [...this.state.players];
                    pos = players.map(function (e) { return e.id; }).indexOf(id);
                    player = { ...players[pos] };

                    this.playAudio(audio.PinkCowBacker);
                    this.playAudio(audio.AwardPinkCow);
                    player.herdData.givenPinkCow = true;
                    player.herdData.hasPinkCow = true;

                    players[pos] = player;

                    this.setState({ players, });

                    pinkCow.show = false;
                    this.setState({ pinkCow, });

                    this.state.room.send("update_cows", { deletePink: {x: movePos.x, y: movePos.y} , deleteCows, });

                    setTimeout(() => {
                        this.signalNewRound();
                    }, 2000);
                }, pinkCow.speed * 1000);
            }, 1500);
        } else {
            //if (this.state.pinkCowHolder == id) {
            //    this.playAudio(pinkCowBackerSFX, false, 0.6);
            //    this.playAudio(awardPinkCowSFX, false, 0.6);
            //}

            this.state.room.send("update_cows", { deletePink: false, deleteCows, });
            setTimeout(() => {
                this.signalNewRound();
            }, 2000);
        }
    }

    showRoundResults(players, pinkCowId) {
        this.setState({ showRoundResults: true, assigningPink: false, });
        this.resetRoundValues();
        setTimeout(() => {
            this.setState({ showRoundResults: false, });
            let statePlayers = [...this.state.players];
            statePlayers.forEach((value, key) => {
                value.herdData.givenPinkCow = false;
                value.showAnswer = false;
                value.herdData.answer = "";
            });
            this.setState({ players: statePlayers, showAnswers: false, showQuestion: false, });

            setTimeout(() => {
                this.awardScores(players, pinkCowId);

            }, 2000);
        }, 3000);
    }

    getRandomElement(array) {
        return array[Math.random() * array.length >> 0];
    }


    awardScores(players, pinkCowId) {
        // TO DO: do cow animations here!

        let cowIds = [];
        for (let i = 0; i < this.state.cows.length; i++) {
            if (this.state.cows[i].show) {
                cowIds.push(i);
            }
        }
        let posList = [];
        let count = 0;
        let scoredCount = 0;
        for (const [key, value] of Object.entries(players)) {
            count++;
            let isLast = count >= this.state.players.length;
            let cowSpeed = 2;
            let statePlayers = [...this.state.players];
            let pos = statePlayers.map(function (e) { return e.id; }).indexOf(value.id);
            let player = { ...statePlayers[pos] };
            if (value.herdData.score > player.herdData.score) {
                scoredCount++;
                setTimeout(() => {
                    statePlayers = [...this.state.players];
                    pos = statePlayers.map(function (e) { return e.id; }).indexOf(value.id);
                    player = { ...statePlayers[pos] };
                    let cows = [...this.state.cows];
                    let cowPos = this.getRandomElement(cowIds);
                    let cow = cows[cowPos];

                    let movePos = this.getMovePosition(`cow-${cow.id}`, `anchor-${player.id}`);
                    cow.x = movePos.x;
                    cow.y = movePos.y;
                    cow.speed = cowSpeed;
                    cow.delete = true;

                    cows[cowPos] = cow;
                    this.setState({ cows });
                    posList.push(cowPos);
                    cowIds = cowIds.filter(x => x != cowPos);
                    //if (isLast) {
                    //    setTimeout(() => {
                    //        this.state.room.send("update_cows", { cows: this.state.cows, pinkCow: this.state.pinkCow, });
                    //    }, cowSpeed * 1000);
                    //}

                    setTimeout(() => {
                        statePlayers = [...this.state.players];
                        pos = statePlayers.map(function (e) { return e.id; }).indexOf(value.id);
                        player = { ...statePlayers[pos] };
                        this.playAudio(audio.AwardCow);
                        player.herdData.score = value.herdData.score;
                        statePlayers[pos] = player;
                        this.setState({ players: statePlayers, });
                    }, cow.speed * 1000);
                }, scoredCount * 500);
            }
            if (isLast) {
                setTimeout(() => {
                    this.awardPinkCow(pinkCowId);
                    this.setState({ question: {}, herdWrangler: {}, });
                }, (scoredCount * 500) + ((cowSpeed + 1) * 1000));
            }
        }
    }

    resetRoundValues() {
        let players = [...this.state.players];
        players.forEach((value, key) => {
            value.herdData.confirmed = false;
            value.herdData.selected = false;
        });
        this.setState({ players, });
    }

    signalNewRound() {
        this.state.room.send("start_round", {});
    }

    doGameOver(winners, players) {
        this.getLeaderboardList(players);

        let winnerText = "";
        let winnerTitle = "";
        if (winners.length > 1) {
            for (let i = 0; i < winners.length; i++) {
                winnerText += winners[i].name;
                if (i != winners.length - 1) winnerText += ", ";
            }
            winnerTitle = "WINNERS!";
        } else {
            winnerText += `${winners[0].name}`;
            winnerTitle = "WINNER!";
        }


        this.setState({ showHeaderFooter: false, winners, winnerText, winnerTitle, showPlayers: false, showPen: false, });

        this.doTitleAnim("WinnersAre");
    }

    getLeaderboardList(players) {
        let statePlayers = [...this.state.players];
        statePlayers.forEach(x => {
            x.scrawlData = players[x.id].scrawlData;
        });

        let leaderboardPlayers = [];
        statePlayers.forEach((x, index) => {
            let boardPlayer = {
                name: x.name,
                score: x.herdData.score,
                avatar: x.avatar,
                hasPinkCow: x.herdData.hasPinkCow,
            };
            leaderboardPlayers.push(boardPlayer);
        });
        leaderboardPlayers = leaderboardPlayers.sort((a, b) => {
            return b.score - a.score;
        });

        for (let i = 0; i < leaderboardPlayers.length; i++) {
            leaderboardPlayers[i].position = this.getPosition(leaderboardPlayers[i], i, leaderboardPlayers);
        }

        this.setState({ leaderboardPlayers, });
    }

    getPosition(player, index, leaderboardPlayers) {
        let position = 1;
        if (index > 0) {
            const playerAbove = leaderboardPlayers[index - 1];
            if (playerAbove != null) {
                if (player.score == playerAbove.score) return playerAbove.position;
                else return playerAbove.position + 1;
            }
        }
        return position;
    }

    getFontSize(player) {
        if (player) {
            let name = player.name;
            let fontSize = "";
            if (name.length <= 3) {
                fontSize = "large";
            } else if (name.length <= 6) {
                fontSize = "medium";
            } else {
                fontSize = "small";
            }
            return fontSize;
        } else {
            return "medium";
        }
    }

    showWinners() {
        this.setState({ gameOver: true, doConfetti: true, });

        this.playAudio(audio.Winner);
        let stateWinners = [...this.state.winners];
        for (let i = 0; i < stateWinners.length; i++) {
            setTimeout(() => {
                let player = { ...stateWinners[i] };
                player.fontSize = this.getFontSize(player);
                player.showSelf = true;
                stateWinners[i] = player;
                this.setState({ winners: stateWinners });
            }, (i * 300));
        }

        setTimeout(() => {
            let stateWinners = [...this.state.winners];
            for (let i = 0; i < stateWinners.length; i++) {
                setTimeout(() => {
                    let player = { ...stateWinners[i] };
                    player.showSelf = false;
                    stateWinners[i] = player;
                    this.setState({ winners: stateWinners });
                }, (i * 300));
            }
            setTimeout(() => {
                this.state.room.send("reached_end", {});
                this.showPlayAgainSection();
            }, 2000);
        }, 5000);
    }

    showPlayAgainSection() {
        this.setState({ showPlayAgainSection: true, gameOver: false, winners: [], winnerText: "", })
    }

    hidePlayAgainSection() {
        this.setState({ showPlayAgainSection: false, });
    }

    playAgain = (confirm = false) => {
        let allConnected = true;
        for (const [key, value] of Object.entries(this.state.playerConnections)) {
            if (value === false) allConnected = false;
        }

        if (!confirm && !allConnected) {
            this.setState({ showStartWarning: true });
        } else {
            this.playAudio(audio.HoverOrClick);
            this.hidePlayAgainSection();
            this.state.room.send("new_game", {});
            this.setState({ showStartWarning: false, });
        }
    }

    differentGame() {
        this.playAudio(audio.HoverOrClick);
        this.hidePlayAgainSection();
        this.state.room.send("change_game", {});
    }

    resetGame(players) {
        //this.awardScores(players);
        let statePlayers = [...this.state.players];
        statePlayers.forEach((value, key) => {
            value.herdData.hasPinkCow = false;
            value.herdData.givenPinkCow = false;
            value.herdData.score = 0;
        });
        this.setState({ players: statePlayers, showHeaderFooter: true, pinkCowHolder: "", showPlayers: true, showPen: true, });
        setTimeout(() => {
            this.state.room.send("start_round", {});
        }, 3000);
    }

    doSkipRound(players) {
        this.setState({ showRoundResults: false, showAnswers: false, assigningPink: false, showNotification: true, notification: "The herd wrangler has left... starting new round." });
        this.awardScores(players);
        this.resetRoundValues();
        this.hideAllPlayerAnswered();

        setTimeout(() => {
            this.setState({ showNotification: false, notification: "", });
            this.signalNewRound();
        }, 4000);
    }

    updateRoundNums(totalRounds, roundNumber) {
        //if (totalRounds < roundNumber) {
        //    totalRounds = roundNumber;
        //}
        this.setState({ roundNumber, totalRounds });
    }

    getTickList() {
        let list = [];
        for (let i = 1; i <= this.state.totalRounds; i++) {
            let stopped = true;
            if (i <= this.state.roundNumber) stopped = false;
            list.push(
                <Lottie
                    options={tickCard}
                    height="5vh"
                    isClickToPauseDisabled={true}
                    isStopped={stopped}
                    style={{ marginLeft: "1vh", marginTop: "auto", marginBottom: "auto", }}
                />
            );
        }
        return list;
    }

    getTitleOptions(context) {
        let options;
        switch (context) {
            case 1:
                options = round1Anim;
                break;
            case 2:
                options = round2Anim;
                break;
            case 3:
                options = round3Anim;
                break;
            case 4:
                options = round4Anim;
                break;
            case 5:
                options = round5Anim;
                break;
            case 6:
                options = round6Anim;
                break;
            case 7:
                options = round7Anim;
                break;
            case 8:
                options = round8Anim;
                break;
            case "Tutorial":
                options = howToPlayAnim;
                break;
            case "Answers":
                options = answersTitleAnim;
                break;
            case "WinnersAre":
                options = winnerIsAnim;
                break;
            default:
                options = round1Anim;
        }
        return options;
    }

    doTitleAnim(context) {
        let mooArray = [
            audio.Cow1,
            audio.Cow2,
            audio.Cow3,
            audio.Cow4,
            audio.Cow5,
            audio.Cow6,
            audio.Cow7,
            audio.Cow8,
            audio.Cow9,
            audio.Cow10,
        ];
        if (context === "Tutorial" || context === "Answers" || context === "WinnersAre") {
            this.playAudio(audio.BannerWoosh);
            setTimeout(() => {
                
                this.playAudio(this.getRandomElement(mooArray));
            }, 1750);
            setTimeout(() => {
                this.playAudio(audio.BannerWoosh);
            }, 4250);
        } else {
            this.playAudio(bannerWooshSFX, false, 1);
            setTimeout(() => {
                this.playAudio(this.getRandomElement(mooArray));
            }, 1250);
            setTimeout(() => {
                this.playAudio(audio.BannerWoosh);
            }, 2500);
        }

        let titles = [...this.state.titles];
        let titleIndex = titles.map(x => x.context).indexOf(context);
        let title = titles[titleIndex];

        title.isPaused = false;
        titles[titleIndex] = title;
        this.setState({ titles });

        this.titleRefs[context].play();
    }

    doTitleComplete(context) {
        let titles = [...this.state.titles];
        let titleIndex = titles.map(x => x.context).indexOf(context);
        let title = titles[titleIndex];

        title.isPaused = true;
        titles[titleIndex] = title;
        this.setState({ titles });

        this.titleRefs[context].stop();
        switch (context) {
            case "Tutorial":
                this.setState({ doingTutorial: true, });
                setTimeout(() => {
                    this.gameBgAudio.volume(0.05);
                    this.state.room.send("show_tutorial", {});
                    this.setState({ showTutorial: true, });
                }, 500);
                break;
            case "Answers":
                setTimeout(() => {
                    this.showAnswers();
                }, 1000);
                break;
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
                this.doNewQuestion();
                break;
            case "WinnersAre":
                this.showWinners();
                break;
        }
    }

    getMovePosition(startId, destId) {
        let start = document.getElementById(startId);
        let dest = document.getElementById(destId);

        if (start) {
            let startPos = start.getBoundingClientRect();
            let destPos = dest.getBoundingClientRect();

            let x;
            let y;

            y = destPos.top - startPos.top;
            x = destPos.left - startPos.left;

            console.log('move pos x : ' + x);
            console.log('move pos y : ' + y);

            return { x, y };
        } else {
            return { x: 0, y: 0, };
        }
    }


    startGame = (confirm = false) => {
        let allConnected = true;
        for (const [key, value] of Object.entries(this.state.playerConnections)) {
            if (value === false) allConnected = false;
        }

        if (!confirm && !allConnected) {
            this.setState({ showStartWarning: true });
        } else {
            this.playAudio(audio.HoverOrClick);
            this.state.room.send("begin_game", { skipTutorial: this.state.tickedSkipTutorial, });
            this.setState({ showStartGame: false, showStartWarning: false, });
        }
    }

    closeStartWarning = () => {
        this.setState({ showStartWarning: false });
    }

    confirmNewGame = () => {
        this.playAgain(true);
    }

    confirmStartGame = () => {
        this.startGame(true);
    }

    toggleSkipTutorial = (e) => {
        if (this.state.showStartGame) {
            console.log("cb value: " + e.target.checked);
            this.setState({ tickedSkipTutorial: e.target.checked });
        }
    }

    getRedirectURL(display = false) {
        let url = display ? process.env.REACT_APP_GAME_CITY_URL_DISPLAY : process.env.REACT_APP_GAME_CITY_URL;
        if (this.state.room) {
            if (this.state.room.name != "game_city_room") {
                url = display ? process.env.REACT_APP_HOME_URL_DISPLAY : process.env.REACT_APP_HOME_URL;
            }
        }
        return url;
    }

    updateToken(token) {
        var url = new URL(window.location.href);

        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`));
        } catch (e) {
            console.warn(e)
        }
    }

    updateCowProperty(index, property, value) {
        let stateCows = [...this.state.cows];
        let cow = { ...stateCows[index] };
        cow[property] = value;
        stateCows[index] = cow;
        this.setState({ cows: stateCows });
    }

    updatePinkCowProperty(property, value) {
let pinkCow = { ...this.state.pinkCow };
        pinkCow[property] = value;
        this.setState({ pinkCow });
    }

    startLocationChecks() {
        this.state.room.send("location_check", { gameId, });
        this.locationCheckInterval = setInterval(() => {
            if (this.state.gotLocationPing) {
                this.setState({ gotLocationPing: false, connectionIssue: false, });
            } else {
                console.log("Host Connection Issue detected");
                this.setState({ connectionIssue: true, });
                this.hostLostConnectionBug();
            }
            this.state.room.send("location_check", { gameId, });
        }, 10000);
    }

    doReconnect = () => {
        if (this.state.reconnectTries < 5) {
            const token = this.getQueryStringValue("token");

            if (this.state.connected == false) {
                this.client.reconnect(token).then(room => {
                    console.log(room.sessionId, "joined", room.name);
                    this.setState({ room: room, roomId: room.id, myId: room.sessionId, connected: true, reconnectionToken: room.reconnectionToken });
                    this.updateToken(room.reconnectionToken);
                    room.send("update_host_token", { reconnectionToken: room.reconnectionToken });

                    room.onStateChange.once((state) => {
                        console.log("this is the first room state!", state);
                        if (state.host.id != room.sessionId) window.location = this.getRedirectURL();
                        Sentry.setUser({ id: state.host.uniqueId });

                        this.setState({ roomState: state, maxVoteCount: state.players.size });
                        if (state.herdData.gameState == GameStates.Loading) {
                            room.send("host_joined_game", { gameId });
                            this.setState({ isGame: true, });
                            setTimeout(() => {
                                this.setState({ showStartGame: true, shrinkLogo: true, });
                            }, 1000);

                            this.startLocationChecks();
                        } else {
                            room.send("change_game", {});
                        }

                        room.state.herdData.listen("gameState", (currentValue, previousValue) => {
                            if (currentValue != GameStates.Loading && currentValue != GameStates.EndGame && this.state.showStartWarning) {
                                this.setState({ showStartWarning: false });
                            }
                            this.setState({ gameState: currentValue });
                        });

                        room.state.players.onAdd((player, key) => {
                            player.listen("connected", (currentValue, previousValue) => {
                                let playerConnections = { ...this.state.playerConnections };
                                playerConnections[key] = currentValue;
                                this.setState({ playerConnections });
                            });
                        });

                        room.state.herdData.cows.onAdd((cow, index) => {
                            console.log("cow added : ", cow, index);
                            let stateCows = [...this.state.cows];
                            stateCows[index] = cow;
                            this.setState({ cows: stateCows });

                            cow.listen("id", (currentValue, previousValue) => {
                                this.updateCowProperty(index, "id", currentValue);
                            });
                            cow.listen("zIndex", (currentValue, previousValue) => {
                                this.updateCowProperty(index, "zIndex", currentValue);
                            });
                            cow.listen("top", (currentValue, previousValue) => {
                                this.updateCowProperty(index, "top", currentValue);
                            });
                            cow.listen("left", (currentValue, previousValue) => {
                                this.updateCowProperty(index, "left", currentValue);
                            });
                            cow.listen("speed", (currentValue, previousValue) => {
                                this.updateCowProperty(index, "speed", currentValue);
                            });
                            cow.listen("direction", (currentValue, previousValue) => {
                                this.updateCowProperty(index, "direction", currentValue);
                            });
                            cow.listen("bounce", (currentValue, previousValue) => {
                                this.updateCowProperty(index, "bounce", currentValue);
                            });
                            cow.listen("x", (currentValue, previousValue) => {
                                this.updateCowProperty(index, "x", currentValue);
                            });
                            cow.listen("y", (currentValue, previousValue) => {
                                this.updateCowProperty(index, "y", currentValue);
                            });
                            cow.listen("show", (currentValue, previousValue) => {
                                this.updateCowProperty(index, "show", currentValue);
                            });
                            cow.listen("shake", (currentValue, previousValue) => {
                                this.updateCowProperty(index, "shake", currentValue);
                            });
                        });

                        room.state.herdData.pinkCow.listen("id", (currentValue, previousValue) => {
                            this.updatePinkCowProperty("id", currentValue);
                        });
                        room.state.herdData.pinkCow.listen("zIndex", (currentValue, previousValue) => {
                            this.updatePinkCowProperty("zIndex", currentValue);
                        });
                        room.state.herdData.pinkCow.listen("top", (currentValue, previousValue) => {
                            this.updatePinkCowProperty("top", currentValue);
                        });
                        room.state.herdData.pinkCow.listen("left", (currentValue, previousValue) => {
                            this.updatePinkCowProperty("left", currentValue);
                        });
                        room.state.herdData.pinkCow.listen("speed", (currentValue, previousValue) => {
                            this.updatePinkCowProperty("speed", currentValue);
                        });
                        room.state.herdData.pinkCow.listen("direction", (currentValue, previousValue) => {
                            this.updatePinkCowProperty("direction", currentValue);
                        });
                        room.state.herdData.pinkCow.listen("bounce", (currentValue, previousValue) => {
                            this.updatePinkCowProperty("bounce", currentValue);
                        });
                        room.state.herdData.pinkCow.listen("x", (currentValue, previousValue) => {
                            console.log("Updating pink cow x : ", currentValue);
                            this.updatePinkCowProperty("x", currentValue);
                        });
                        room.state.herdData.pinkCow.listen("y", (currentValue, previousValue) => {
                            console.log("Updating pink cow y : ", currentValue);
                            this.updatePinkCowProperty("y", currentValue);
                        });
                        room.state.herdData.pinkCow.listen("show", (currentValue, previousValue) => {
                            console.log("Updating pink cow show : ", currentValue);
                            this.updatePinkCowProperty("show", currentValue);
                        });
                        room.state.herdData.pinkCow.listen("shake", (currentValue, previousValue) => {
                            this.updatePinkCowProperty("shake", currentValue);
                        });
                    });
                    room.onStateChange((state) => {
                        console.log(room.name, "has new state:", state);
                        this.setState({ roomState: state, maxVoteCount: state.players.size });
                    });

                    room.onMessage("location_confirmed", (message) => {
                        console.log("location_confirmed", "received on", room.name, message);
                        this.setState({ gotLocationPing: true, });
                    });


                    room.onMessage("show_players", (message) => {
                        console.log("show_players", "received on", room.name, message);
                        for (const [key, value] of Object.entries(message.players)) {
                            this.addPlayer(value);
                        }
                    });
                    room.onMessage("clicked_begin_game", (message) => {
                        this.setState({ showStartGame: false, })
                    });
                    room.onMessage("begin_game", (message) => {
                        console.log("begin_game", "received on", room.name, message);
                        if ((room.state.herdData.gameState == GameStates.Loading || room.state.herdData.gameState == GameStates.Idle) && !this.state.gameBegun) {
                            this.gameBgAudio.volume(0.2);
                            this.setState({ showHeaderFooter: true, showTutorial: false, gameBegun: true, showStartGame: false, });
                            setTimeout(() => {
                                this.setState({ doingTutorial: false, shrinkLogo: true, });
                                setTimeout(() => {
                                    this.setState({ showPlayers: true, showPen: true, });
                                    room.send("start_playing", {});
                                    setTimeout(() => {
                                        room.send("start_round", {});
                                    }, 2000);
                                }, 1000);
                            }, 1000);
                        }
                    });
                    room.onMessage("update_round_number", (message) => {
                        console.log("update_round_number", "received on", room.name, message);
                        this.updateRoundNums(message.totalRounds, message.roundNumber);
                    });
                    room.onMessage("player_joined", (message) => {
                        this.playAudio(joinedSFX, false, 0.6);
                        console.log("player_joined", "received on", room.name, message);
                        this.addPlayer(message.player);
                    });
                    room.onMessage("player_leave", (message) => {
                        console.log("player_leave", "received on", room.name, message);
                        this.removePlayer(message.sessionId);
                    });
                    room.onMessage("new_question", (message) => {
                        console.log("new_question", "received on", room.name, message);
                        this.updateRoundNums(message.totalRounds, message.roundNumber);
                        this.setState({ question: message.newQ, herdWrangler: message.herdWrangler, });
                        this.doTitleAnim(message.roundNumber);
                    });
                    room.onMessage("player_answered", (message) => {
                        console.log("player_answered", "received on", room.name, message);
                        this.playAudio(audio.Ready);
                        this.showPlayerAnswered(message.sessionId, message.answer);
                    });
                    room.onMessage("all_answered", (message) => {
                        console.log("all_answered", "received on", room.name, message);
                        let statePlayers = [...this.state.players];
                        statePlayers.forEach((value, key) => {
                            let player = message.players[value.id];
                            if (player) {
                                value.herdData.answer = player.herdData.answer;
                            }
                        });
                        this.setState({ players: statePlayers });
                        this.doTitleAnim("Answers");
                        //this.showAnswers(message.players);
                    });
                    room.onMessage("answer_selected", (message) => {
                        console.log("answer_selected", "received on", room.name, message);
                        this.playAudio(audio.SelectingAnswers);
                        this.selectAnswer(message.id, message.selected);
                    });
                    room.onMessage("answers_chosen", (message) => {
                        console.log("answers_chosen", "received on", room.name, message);
                        this.playAudio(audio.SelectedButton);
                        this.hideSelected(message.idList);
                    });
                    room.onMessage("skip_answers_chosen", (message) => {
                        console.log("skip_answers_chosen", "received on", room.name, message);
                        this.playAudio(audio.SelectedButton);
                        this.unselectAll();
                    });
                    room.onMessage("start_pink_cow", (message) => {
                        console.log("start_pink_cow", "received on", room.name, message);
                        this.startAssignPinkCow();
                    });
                    room.onMessage("assign_pink_cow", (message) => {
                        console.log("assign_pink_cow", "received on", room.name, message);
                        this.playAudio(audio.SelectedButton);
                        this.showRoundResults(message.players, message.id);
                    });
                    room.onMessage("skip_assign_pink_cow", (message) => {
                        console.log("skip_assign_pink_cow", "received on", room.name, message);
                        this.playAudio(audio.SelectedButton);
                        this.showRoundResults(message.players, null);
                    });
                    room.onMessage("game_over", (message) => {
                        console.log("game_over", "received on", room.name, message);
                        this.doGameOver(message.winners, message.players);
                    });
                    room.onMessage("skip_round", (message) => {
                        console.log("skip_round", "received on", room.name, message);
                        this.doSkipRound(message.players);
                    });
                    room.onMessage("animate_potato", (message) => {
                        console.log("animate_potato", "received on", room.name, message);
                        this.animatePotato(message.id, message.animation);
                    });
                    room.onMessage("moo", (message) => {
                        console.log("moo", "received on", room.name, message);
                        let mooArray = [
                            audio.Cow1,
                            audio.Cow2,
                            audio.Cow3,
                            audio.Cow4,
                            audio.Cow5,
                            audio.Cow6,
                            audio.Cow7,
                            audio.Cow8,
                            audio.Cow9,
                            audio.Cow10,
                        ];
                        this.playAudio(this.getRandomElement(mooArray));
                        this.doMoo(message.sessionId, message.mooIsPaused);
                    });
                    room.onMessage("new_game", (message) => {
                        console.log("new_game", "received on", room.name, message);
                        this.resetGame(message.players);
                    });
                    room.onMessage("change_game", (message) => {
                        console.log("change_game", "received on", room.name, message);
                        this.setState({ redirect: `${this.getRedirectURL()}/lobby/?token=${this.state.reconnectionToken}` });
                        this.state.room.leave(false);
                    });
                    room.onMessage("update_timer", (message) => {
                        //console.log("update_timer", "received on", room.name, message);
                        if (message.count <= 10) {
                            if (message.count <= 5) {
                                if (message.count <= 0) {
                                    setTimeout(() => {
                                        this.setState({ showTimer: false, });
                                    }, 2000);
                                }
                                this.setState({ timerOptions: timerEnd });
                            } else {
                                this.setState({ timerOptions: timerTurning });
                            }
                            this.setState({ timer: message.count, showTimer: true, });
                        } else {
                            this.setState({ showTimer: false, });
                        }
                    });
                    room.onMessage("update_cows", (message) => {
                        //console.log("update_cows", "received on", room.name, message);
                        this.setState({ cows: message.cows, pinkCow: message.pinkCow, });
                    });

                    room.onMessage("begin_tutorial", (message) => {
                        console.log("begin_tutorial", "received on", room.name, message);
                        this.setState({ showStartGame: false, });
                        setTimeout(() => {
                            this.doTitleAnim("Tutorial");
                        }, 3000);
                    });
                    room.onMessage("update_skip_vote", (message) => {
                        console.log("update_skip_vote", "received on", room.name, message);
                        this.setState({ skipTutorialCount: message.voteCount });
                    });
                    room.onError((code, message) => {
                        console.log(this.client.id, "couldn't join", room.name);
                        //LoggingService.logError(message, code);
                    });
                    room.onLeave((code) => {
                        console.log(this.client.id, "left", room.name);
                        if (!this.state.redirect) {
                            this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                            setTimeout(() => {
                                this.doReconnect();
                            }, 1000);
                        }
                    });
                }).catch(e => {
                    console.log("JOIN ERROR", e);
                    this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                    const message = e.message ? e.message : "An error occured Hosting Herd.";
                    //LoggingService.logError(message, e);
                    setTimeout(() => {
                        this.doReconnect();
                    }, 1000);
                });
            }
        } else {
            this.setState({ redirect: `${this.getRedirectURL()}/lobby` });
        }
    }


    render() {
        //console.log("TUTORIAL IMAGES: " + tutorialImages);
        if (this.state.redirect) {
            return (
                <React.Fragment>
                    <div id="gameContainer" className={styles.gameContainer}>
                        <div className={styles.loadingContainer}>
                            <Loading loadingText={"Sending you to the lobby!"} />
                        </div>
                    </div>
                    <div style={{ opacity: 0 }}>
                        <Route path="/" render={() => (window.location = this.state.redirect)} />
                    </div>'
                </React.Fragment>
            )
        }
        return (
            <div>
                <div id="gameContainer" className={styles.gameContainer}>
                    <Menu room={this.state.room} toggleMenu={this.toggleMenu} menuOpen={this.state.menuOpen} toggleMute={this.toggleMute} muted={this.state.muted} />
                    {
                        this.state.showStartWarning && [GameStates.Loading, GameStates.EndGame].includes(this.state.gameState) &&
                        <ErrorModal
                            title={"Are you ready to play?"}
                            styles={"d-flex"}
                            message={"It looks like all the players might not be connected to the game, are you sure you would like to start?"}
                            callback={this.closeStartWarning}
                            callbackText={"No"}
                            callback2={this.state.showStartGame ? this.confirmStartGame : this.confirmNewGame}
                            callbackText2={"Yes"}
                        />
                    }
                    {
                        this.state.doConfetti &&
                        <Confetti
                            width={window.innerWidth}
                            height={window.innerHeight}
                            initialVelocityY={20}
                            numberOfPieces={500}
                            recycle={false}
                            confettiSource={{ x: window.innerWidth / 2, y: window.innerHeight + 10, width: window.innerWidth + 10, height: 0 }}
                            initialVelocityY={{ min: -10, max: -30, }}
                            initialVelocityX={{ min: -10, max: 10, }}
                            onConfettiComplete={() => this.setState({ doConfetti: false, })}
                        />
                    }
                    {
                        !this.state.isGame ?
                            <div id="logoSection" className={`${styles.logoSection}`}>
                                <img src={logoImg} className={styles.logo} />
                            </div>
                            :
                            <React.Fragment>
                                {
                                    this.state.doingTutorial ?
                                        <Tutorial show={this.state.showTutorial} skipCount={this.state.skipTutorialCount} playerCount={this.state.maxVoteCount} room={this.state.room} />
                                        :
                                        null
                                }
                                {
                                    this.state.titles.map((title, index) => {
                                        return <Lottie
                                            options={title.options}
                                            width="100%"
                                            height="100%"
                                            isClickToPauseDisabled={true}
                                            style={{ position: 'fixed', left: 0, right: 0, top: 0, bottom: 0, zIndex: 20, touchAction: "none", userSelect: "none", opacity: title.isPaused ? 0 : 1, }}
                                            eventListeners={[
                                                {
                                                    eventName: 'complete',
                                                    callback: () => this.doTitleComplete(title.context),
                                                },
                                            ]}
                                            isPaused={title.isPaused}
                                            isStopped={title.isPaused}
                                            ref={animation => {
                                                this.titleRefs[title.context] = animation;
                                            }}
                                        />
                                    })
                                }
                                <div id="logoSection" className={`${styles.logoSection} ${this.state.shrinkLogo ? styles.corner : ""}`}>
                                    <img src={logoImg} className={styles.logo} />
                                </div>
                                <div className={`${styles.counterSection} ${this.state.showHeaderFooter ? "" : styles.hidden}`}>
                                    <div className={styles.counterText}>{`${this.state.roundNumber}/${this.state.totalRounds}`}</div>
                                    <div className={styles.tickList}>
                                        {
                                            this.getTickList()
                                        }
                                    </div>
                                </div>
                                <div className={styles.roomCode}>
                                    <div className={styles.textBox}>
                                        <div className={styles.text}>{this.getRedirectURL(true)}</div>
                                        <div className={styles.text}>Code: <span className={`${styles.text} ${styles.code}`}>{this.state.roomId}</span></div>
                                        <div className={styles.iconsBox}>
                                            <div className={styles.muteToggle} onClick={() => this.toggleMute()}>
                                                <img src={this.state.muted ? mutedIcon : unmutedIcon} className={styles.muteIcon} />
                                            </div>
                                            {
                                                fullscreenAvailable ?
                                                    <div className={styles.muteToggle} onClick={() => { this.toggleFullScreen() }}>
                                                        <img src={fullscreenIcon} className={styles.muteIcon} />
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <div className={styles.muteToggle} onClick={() => this.toggleMenu()}>
                                                <img src={helpIcon} className={styles.muteIcon} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.qrCodeBox}>
                                        <QRCode
                                            className={styles.qrCode}
                                            value={`${this.getRedirectURL()}/play/?qrCode=${this.state.roomId}`}
                                            bgColor="rgba(0,0,0,0)"
                                        />
                                    </div>
                                </div>
                                {
                                    this.state.gameOver ?
                                        <div className={styles.winnersContainer}>
                                            <div className={styles.winnerTitle}>{this.state.winnerTitle}</div>
                                            <div className={styles.winnerBox}>
                                                {
                                                    this.state.winners.map(x => {
                                                        return <div className={`${styles.winner} ${x.showSelf ? styles.show : ""}`}>
                                                            <img src={winnerBg} className={styles.winnerBg} />
                                                            <div className={styles.winPotato}>
                                                                <Lottie
                                                                    options={getAvatarById(x.avatar).idleAnim}
                                                                    width="100%"
                                                                    height="100%"
                                                                    isClickToPauseDisabled={true}
                                                                />
                                                            </div>
                                                            <div className={`${styles.winnerText} ${x.fontSize == "large" ? styles.large : x.fontSize == "medium" ? styles.medium : styles.small}`}>{x.name}</div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                                <div className={`${styles.playAgainSection} ${this.state.showPlayAgainSection ? styles.show : ""}`}>
                                    <div className={styles.buttonsSection}>
                                        <div className={styles.choiceButton} onClick={() => this.playAgain()}>Play Again</div>
                                        <div className={`${styles.choiceButton} ${styles.smaller}`} onClick={() => this.differentGame()}>Different Game</div>
                                    </div>
                                    <div className={styles.leaderboardSection}>
                                        {
                                            this.state.leaderboardPlayers.map((x, index) => {
                                                return <div className={styles.boardRow}>
                                                    <div className={styles.numberBox}>
                                                        {
                                                            x.hasPinkCow ?
                                                                <img className={styles.cowHead} src={pinkCowHead} />
                                                                :
                                                                <div className={styles.positionText}>{x.position}</div>
                                                        }
                                                    </div>
                                                    <div className={styles.nameBox}>{x.name}</div>
                                                    <div className={styles.scoreBox}>
                                                        <div className={styles.text}>{x.score}</div>
                                                        <div className={`${styles.text} ${styles.smaller}`}>PTS</div>
                                                    </div>
                                                    <div className={styles.potato}>
                                                        <Lottie
                                                            options={getAvatarById(x.avatar).idleAnim}
                                                            width="100%"
                                                            height="100%"
                                                            isClickToPauseDisabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                                <div className={`${styles.startGameSection} ${styles.center} ${this.state.showStartGame ? styles.show : ""}`}>
                                    <div className={styles.choiceButton} onClick={() => this.startGame()}>Start Game</div>
                                    <div className={`${styles.choiceButton} ${styles.smaller}`} onClick={() => this.differentGame()}>Go To Lobby</div>
                                    <div className={styles.skipBox}>
                                        <input className={styles.checkbox} type="checkbox" id="checkbox" name="checkbox" onChange={this.toggleSkipTutorial} />
                                        <label for="checkbox">Skip Tutorial</label>
                                    </div>
                                </div>
                                <div className={`${styles.notification} ${this.state.showNotification ? styles.show : styles.hide}`}>
                                    <img src={notificationBg} className={styles.notificationBg} />
                                    {
                                        this.state.showWranglerNotification ?
                                            <div className={styles.wranglerSection}>
                                                <div className={styles.wranglerPotato}>
                                                    <Lottie
                                                        options={this.state.notificationPotato}
                                                        width="100%"
                                                        height="100%"
                                                        isClickToPauseDisabled={true}
                                                    />
                                                </div>
                                                <div className={styles.textBox}>
                                                    <div className={styles.wranglerName}>{this.state.notificationName}</div>
                                                    <div className={styles.wranglerText}>{this.state.notification}</div>
                                                </div>
                                            </div>
                                            :
                                            this.state.showPickNotification ?
                                                <div className={styles.pickPlayers}>
                                                    <div className={styles.wranglerBox}>
                                                        <div className={styles.potato}>
                                                            <Lottie
                                                                options={this.state.notificationPotato}
                                                                width="100%"
                                                                height="100%"
                                                                isClickToPauseDisabled={true}
                                                            />
                                                        </div>
                                                        <div className={`${styles.name} ${this.state.assigningPink ? styles.pink : styles.green}`}>{this.state.notificationName}</div>
                                                    </div>
                                                    <div className={styles.title}>{this.state.notification}</div>
                                                </div>
                                                :
                                                null
                                    }
                                </div>
                                <div id="playerColumnLeft" className={`${styles.playerColumn} ${styles.left} ${this.state.showPlayers ? "" : styles.hide}`}>
                                    {
                                        this.populateLeftColumn()
                                    }
                                </div>
                                <div id="middleColumn" className={styles.middleColumn}>
                                    <div className={`${styles.timerSection} ${this.state.showTimer ? styles.showTimer : ""}`}>
                                        <div className={styles.timerBg}></div>
                                        <Lottie options={this.state.timerOptions} width="17vh" height="17vh" isClickToPauseDisabled={true} />
                                        <div className={styles.timer}>{this.state.timer}</div>
                                    </div>
                                    <div className={`${styles.questionSection} ${this.state.showQuestion ? "" : styles.hidden}`}>
                                        <div className={styles.questionBox}>
                                            <div className={styles.text}>{this.state.question.question}</div>
                                            <img src={questionFooter} className={styles.questionFooter} />
                                        </div>
                                    </div>
                                    <div class={`${styles.penSection} ${this.state.showPen ? "" : styles.hidden}`}>
                                        <img src={penBack} className={styles.penBack} />
                                        <div className={styles.cowsSection}>
                                            <div className={styles.cowBox}>
                                                <div className={styles.cowRow}>
                                                    {
                                                        this.state.pinkCow ?
                                                            <img id={`pink-cow`} src={this.state.pinkCow.direction == 1 ? pinkCowLeftImg : pinkCowRightImg} className={`${styles.cow} ${styles.pink} ${this.state.pinkCow.shake ? styles.shake : ""} ${this.state.pinkCow.bounce ? styles.bounce : ""}`} style={{
                                                                left: `${this.state.pinkCow.left}%`,
                                                                top: `-100%`,
                                                                transition: `transform ${this.state.pinkCow.speed}s ease, left ${this.state.pinkCow.speed}s linear`,
                                                                transform: `translate(${this.state.pinkCow.x}px, ${this.state.pinkCow.y}px) scale(${this.state.pinkCow.show ? 1 : 0})`,
                                                            }} />
                                                            :
                                                            null
                                                    }
                                                </div>
                                                <div className={styles.cowRow}>
                                                    {
                                                        this.state.cows.map((cow, index) => {
                                                            if (cow.id >= 0 && cow.id <= 3 && cow != null) {
                                                                return <img id={`cow-${cow.id}`} src={cow.direction == 1 ? cowLeftImg : cowRightImg} className={`${styles.cow} ${cow.bounce ? styles.bounce : ""}`} style={{
                                                                    left: `${cow.left}%`,
                                                                    top: `${cow.top}%`,
                                                                    transition: `transform ${cow.speed}s ease, left ${cow.speed}s linear`,
                                                                    transform: `translate(${cow.x}px, ${cow.y}px) scale(${cow.show ? 1 : 0})`,
                                                                    zIndex: cow.zIndex,
                                                                }} />
                                                            }
                                                        })
                                                    }
                                                </div>
                                                <div className={styles.cowRow}>
                                                    {
                                                        this.state.cows.map((cow, index) => {
                                                            if (cow.id >= 4 && cow.id <= 7 && cow != null) {
                                                                return <img id={`cow-${cow.id}`} src={cow.direction == 1 ? cowLeftImg : cowRightImg} className={`${styles.cow} ${cow.bounce ? styles.bounce : ""}`} style={{
                                                                    left: `${cow.left}%`,
                                                                    top: `${cow.top}%`,
                                                                    transition: `transform ${cow.speed}s ease, left ${cow.speed}s linear`,
                                                                    transform: `translate(${cow.x}px, ${cow.y}px) scale(${cow.show ? 1 : 0})`,
                                                                    zIndex: cow.zIndex,
                                                                }} />
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <img src={penFront} className={styles.penFront} />
                                    </div>
                                </div>
                                <div id="playerColumnRight" className={`${styles.playerColumn} ${styles.right} ${this.state.showPlayers ? "" : styles.hide}`}>
                                    {
                                        this.populateRightColumn()
                                    }
                                </div>
                                {/*<div style={{ position: "absolute", display: "flex", flexDirection: "column", zIndex: 10000 }}>*/}
                                {/*    {*/}
                                {/*        this.state.titles.map((title) => {*/}
                                {/*            return <button onClick={() => this.doTitleAnim(title.context)}>{title.context} Title</button>*/}
                                {/*        })*/}
                                {/*    }*/}
                                {/*</div>*/}
                            </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}
